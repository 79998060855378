// extracted by mini-css-extract-plugin
export var border1 = "index-module--border-1--62664";
export var border2 = "index-module--border-2--cf05d";
export var border3 = "index-module--border-3--7805a";
export var border4 = "index-module--border-4--6b688";
export var container = "index-module--container--c73a9";
export var dark = "index-module--dark--9e627";
export var large = "index-module--large--74bf6";
export var ldsRing = "index-module--lds-ring--a265c";
export var light = "index-module--light--f29fd";
export var medium = "index-module--medium--e12ea";
export var primaryPressed = "index-module--primary-pressed--59aca";
export var xlarge = "index-module--xlarge--b4e69";